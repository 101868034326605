import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import { selectByDimensionType, selectDimensionByDbtId } from '@/api/pay';
import { OK } from '@/common';
import { message } from 'ant-design-vue/es';
import dayjs from 'dayjs';
export default function useFilterHeader() {
  const payStatusList = [{
    label: '全部',
    value: null
  }, {
    label: '未支付',
    value: '0'
  }, {
    label: '支付失败',
    value: '10'
  }, {
    label: '支付成功',
    value: '20'
  }, {
    label: '已退款',
    value: '30'
  }];
  const payStatusListSmall = [{
    label: '支付成功',
    value: '20'
  }, {
    label: '已退款',
    value: '30'
  }];
  const shipStatusList = [{
    label: '全部',
    value: null
  }, {
    label: '未下发',
    value: '0'
  }, {
    label: '已下发',
    value: '10'
  }, {
    label: '已回收',
    value: '12'
  }];
  const reshipStatusList = [{
    label: '全部',
    value: null
  }, {
    label: '未复送',
    value: '0'
  }, {
    label: '复送中',
    value: '10'
  }, {
    label: '已复送',
    value: '20'
  }];
  const originFilterForm = {
    dbtId: undefined,
    query_time: '1',
    time_range: [dayjs().subtract(8, 'day').startOf('day').format('YYYYMMDD'), dayjs().subtract(1, 'day').endOf('day').format('YYYYMMDD')],
    refundTime: [],
    quick_time: '7daysYesterday',
    orderUnion: '',
    userIdentityUnion: '',
    payStatus: null,
    shipStatus: undefined,
    reshipStatus: undefined,
    chnlFlag: '',
    payChnl: undefined,
    prodId: '',
    prodType: undefined,
    pkg: '',
    appVer: undefined
  }; // 初始筛选表单
  const quickTimeList = [{
    label: '今日',
    value: 'today'
  }, {
    label: '昨日',
    value: 'yesterday'
  }, {
    label: '近7日',
    value: '7days'
  }, {
    label: '7日',
    value: '7daysYesterday'
  }, {
    label: '30日',
    value: '30days'
  }]; // 快捷时间
  const refundStatusList = [{
    label: '全部',
    value: null
  },
  // { label: '退款开始', value: 0 },
  {
    label: '审核驳回',
    value: 12
  }, {
    label: '审批撤回',
    value: 11
  }, {
    label: '审批中',
    value: 10
  }, {
    label: '退款中',
    value: 30
  }, {
    label: '退款请求失败',
    value: 31
  }, {
    label: '退款失败',
    value: 32
  }, {
    label: '已退款',
    value: 40
  }, {
    label: '无法退款',
    value: 50
  }]; // 退款状态
  const filterForm = reactive({
    dbtId: undefined,
    query_time: '1',
    time_range: [dayjs().subtract(8, 'day').startOf('day').format('YYYYMMDD'), dayjs().subtract(1, 'day').endOf('day').format('YYYYMMDD')],
    refundTime: [],
    quick_time: '7daysYesterday',
    orderUnion: '',
    userIdentityUnion: '',
    payStatus: null,
    shipStatus: undefined,
    reshipStatus: undefined,
    chnlFlag: '',
    payChnl: undefined,
    prodId: '',
    prodType: undefined,
    pkg: '',
    appVer: undefined
  }); // 筛选表单
  const verList = ref([]); // 需要从API获取应用版本列表
  const isOpen = ref(false); // 是否展开
  const appList = ref([]); // 产品列表
  const prodList = ref([]); // 道具列表
  const chnlList = ref([]); // 支付渠道列表
  const appName = ref(''); // 应用中文名
  const activeKey = ref('1'); // 当前tab
  const datePickerRef = ref(null);
  const datePickerRef2 = ref(null);
  const getNode = triggerNode => triggerNode.parentNode || document.body;
  const disabledDate1 = current => {
    return current && current.diff(filterForm.time_range[0], 'days') > 31;
  }; // 订单创建时间选择器禁用日期
  const changeQuickTime = value => {
    datePickerRef.value.timeStart = dayjs('00:00:00', 'HH:mm:ss');
    datePickerRef.value.timeEnd = dayjs('23:59:59', 'HH:mm:ss');
    filterForm.quick_time = value;
    switch (value) {
      case 'today':
        filterForm.time_range = [dayjs().startOf('day').format('YYYYMMDD'), dayjs().endOf('day').format('YYYYMMDD')];
        break;
      case 'yesterday':
        filterForm.time_range = [dayjs().subtract(1, 'day').startOf('day').format('YYYYMMDD'), dayjs().subtract(1, 'day').endOf('day').format('YYYYMMDD')];
        break;
      case '7daysYesterday':
        filterForm.time_range = [dayjs().subtract(8, 'day').startOf('day').format('YYYYMMDD'), dayjs().subtract(1, 'day').endOf('day').format('YYYYMMDD')];
        break;
      case '7days':
        filterForm.time_range = [dayjs().subtract(7, 'day').startOf('day').format('YYYYMMDD'), dayjs().subtract(0, 'day').endOf('day').format('YYYYMMDD')];
        break;
      case '30days':
        filterForm.time_range = [dayjs().subtract(31, 'day').startOf('day').format('YYYYMMDD'), dayjs().subtract(1, 'day').endOf('day').format('YYYYMMDD')];
        break;
    }
  }; // 快捷时间选择
  const filterOption = (input, option) => {
    return option.label.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0 || option.value.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0;
  }; // 下拉搜索label or value
  const getAppData = async () => {
    try {
      const {
        code,
        data
      } = await selectByDimensionType({
        dimensionType: 'dbt_id'
      });
      if (code === OK) {
        appList.value = data.map(item => {
          return {
            ...item,
            label: item.dimensionName,
            value: item.dimensionCode
          };
        });
      }
    } catch (e) {}
  }; // 获取产品列表
  const changeApp = () => {
    getChannel([filterForm.dbtId]);
    //获取应用中文名
    appList.value.forEach(item => {
      if (item.value === filterForm.dbtId) {
        appName.value = item.dimensionName;
      }
    });
  }; // 获取应用中文名
  const getChannel = async arr => {
    try {
      const {
        code,
        data
      } = await selectDimensionByDbtId(arr);
      if (code === '0') {
        var _data$app_version, _data$prodId, _data$pay_chnl;
        filterForm.appVer = undefined;
        filterForm.prodType = undefined;
        filterForm.payChnl = undefined;
        verList.value = (data === null || data === void 0 || (_data$app_version = data.app_version) === null || _data$app_version === void 0 ? void 0 : _data$app_version.map(item => {
          return {
            ...item,
            checked: false,
            label: item.dimensionName,
            value: item.dimensionCode
          };
        })) || [];
        verList.value = [{
          label: '全部',
          value: null
        }, ...verList.value];
        prodList.value = (data === null || data === void 0 || (_data$prodId = data.prodId) === null || _data$prodId === void 0 ? void 0 : _data$prodId.map(item => {
          return {
            ...item,
            checked: false,
            label: item.dimensionName ? item.dimensionName + '(' + item.dimensionCode + ')' : item.dimensionCode,
            value: item.dimensionCode
          };
        })) || [];
        chnlList.value = (data === null || data === void 0 || (_data$pay_chnl = data.pay_chnl) === null || _data$pay_chnl === void 0 ? void 0 : _data$pay_chnl.map(item => {
          return {
            ...item,
            checked: false,
            label: item.dimensionName ? item.dimensionName + '(' + item.dimensionCode + ')' : item.dimensionCode,
            value: item.dimensionCode
          };
        })) || [];
        chnlList.value = [{
          label: '全部',
          value: null
        }, ...chnlList.value];
      }
    } catch (e) {}
  }; // 获取应用版本列表
  const changeTime = () => {
    filterForm.time_range[1] = filterForm.time_range[0];
    if (!filterForm.time_range[0]) {
      filterForm.time_range = [];
    }
  }; // 订单创建时间选择器change事件
  const changeRefundTime = () => {
    filterForm.refundTime[1] = filterForm.refundTime[0];
    if (!filterForm.refundTime[0]) {
      filterForm.refundTime = [];
    }
  }; // 退款发起时间选择器change事件
  const changeEndTime = () => {
    // 如果结束时间超过开始时间31天，则提示
    if (activeKey.value === '1' && dayjs().diff(filterForm.time_range[0], 'days') > 31) {
      message.warning('结束时间不能超过开始时间31天');
      // 结束时间等于开始时间+31天
      return filterForm.time_range[1] = filterForm.time_range[0].add(31, 'day').endOf('day');
    }
    filterForm.time_range[1] = dayjs();
  }; // 此刻
  const changeRefundEndTime = () => {
    filterForm.refundTime[1] = dayjs();
  }; // 退款发起时间此刻
  const changeQueryTime = () => {
    filterForm.payStatus = filterForm.query_time === '1' ? null : '20';
  }; // 查询时间change事件
  onMounted(() => {
    getAppData();
  }); // 挂载
  return {
    payStatusList,
    payStatusListSmall,
    shipStatusList,
    reshipStatusList,
    isOpen,
    quickTimeList,
    refundStatusList,
    verList,
    chnlList,
    filterForm,
    originFilterForm,
    activeKey,
    appList,
    appName,
    getNode,
    disabledDate1,
    changeQuickTime,
    filterOption,
    changeApp,
    changeTime,
    changeRefundTime,
    changeEndTime,
    changeRefundEndTime,
    datePickerRef,
    datePickerRef2,
    changeQueryTime
  };
}